<template>
  <div :class="isMobileOrTablet ? 'md:px-2 lg:px-0' : ''">
    <div
      class="w-full p-4 md:px-11 md:pr-2 h-full -mt-10 md:-mt-4"
      :class="
        isMobileOrTablet
          ? 'md:px-2 lg:px-9 xl:px-8'
          : 'md:px-9 xl:px-0 xl:max-w-1400 xl:mx-auto'
      "
    >
      <div class="relative mb-4">
        <div class="flex items-center gap-x-3">
          <img
            src="@/assets/svg/icons/icons-flower.svg"
            alt="Icono de flor"
            class="ml-6 w-6 h-6"
          />
          <span
            class="text-xl lg:text-2xl font-semibold font-bodoni tracking-wider"
            >{{ blok.title }}</span
          >
        </div>
        <div class="w-1 h-16 bg-[#3E5391] absolute top-1 left-2 z-10"></div>
      </div>
      <div
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-4 xl:gap-x-0 gap-y-12"
      >
        <Product
          :currency="currency"
          v-for="(product, index) in products"
          :key="index"
          :data="product"
          class="cursor-pointer flex-shrink-0 p-4 lg:py-4 lg:px-4 mdplus:box-border mdplus:border-1 mdplus:border-transparent mdplus:hover:border-others-grey2 w-[170px] md:w-full"
        />
      </div>
    </div>
    <div
      class="flex justify-center mt-10 md:mt-14 lg:mt-20 mb-14 md:mb-20 lg:mb-24"
    >
      <NuxtLink
        :to="blok?.url?.url ? blok.url.url : `/${locale}`"
        class="h-14 w-3/6 md:w-3/12 xl:w-2/12 grid place-items-center text-sm tracking-wider rounded uppercase bg-others-black font-bold text-white hover:animate-[slide-in_0.83s] hover:bg-[200%_100%] hover:bg-[linear-gradient(270deg,#212121_50%,#000_0)] animate-[get-light_0.25s] transition-all duration-200 hover:cursor-pointer"
        style="background-size: 200% 100%"
      >
        <span>{{ blok.linktext }}</span>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
  currency: Object,
  global: Object,
})

const { locale } = useI18n()

const { isMobileOrTablet } = useDevice()

const products = ref([])

const productIds = props.blok.productsids.split(',').map((n) => parseInt(n))
const route = props.blok.routeproducts.route
const maxProducts = props.blok?.maxProducts
  ? parseInt(props.blok.maxProducts)
  : null

const globalIdProducts = props.global.products
const globalRouteProducts = props.global.routes

const getProducts = () => {
  if (productIds.length > 0) {
    let productsByIds = productIds.map((id) => {
      return globalIdProducts[id]
    })
    productsByIds.sort((a, b) => b.stock - a.stock)
    products.value = productsByIds
  } else {
    const routeSeotag = route.params.seotag
    let productsRoute = globalRouteProducts[routeSeotag]
    productsRoute.sort((a, b) => b.stock - a.stock)
    products.value = productsRoute
  }

  if (maxProducts) {
    products.value = products.value.slice(0, maxProducts)
  }
}

getProducts()
</script>
